import { LojaProps } from '../types/loja';

export const formatarOptionLoja = (
  loja: LojaProps,
): { label: string; value: number } => {
  const codigoFormatado = loja.cod_loja.toString().padStart(2, '0');

  return {
    label: `${codigoFormatado} - ${loja.des_loja} (${loja.des_cidade})`,
    value: loja.cod_loja,
  };
};
