import * as yup from 'yup';

export interface FormSimilar {
  cod_similar: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_similar: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  produtos: {
    produtos: Produto[];
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export interface Produto {
  id?: string;
  cod_produto: string;
  des_produto: string;
}

export const schemaSimilar = yup
  .object({
    produtos: yup.object().shape({
      cod_produto: yup.string().required(),
      des_produto: yup.number().required(),
    }),
  })
  .required();
