import Spinner from '@atlaskit/spinner';
import { GridRowParams } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useState } from 'react';

import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';

import { toast } from 'react-toastify';
import Search from '~/components/Search';

import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { Container } from './styles';
import { Especie, SerieNF } from './types';

import InputNumber from '~/components/Inputs/InputNumber';
import InputSelect from '~/components/Inputs/InputSelect';
import ToggleDefault from '~/components/ToggleDefault';
import useAuth from '~/hooks/useAuth';
import { errorHandler } from '~/utils/ErrorHandler';
import { LojaProps } from './types/loja';
import { formatarOptionLoja } from './utils/formatarOptionLoja';
import { useForm } from 'react-hook-form';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const MySwal = withReactContent(Swal);

const Operador: React.FC = () => {
  const infoLoja = JSON.parse(localStorage.YOUR_KEY_USER_DATA);

  const user = useAuth();

  const [loader, setLoader] = useState<boolean>(false);

  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const [iniInicializado, setInitInicializado] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [formData, setFormData] = useState<SerieNF>({
    num_serie: {
      value: null,
      isInvalid: false,
      isRequired: true,
    },

    cod_loja: {
      value: {
        value: infoLoja.loja_data?.cod_loja,
        label: `${infoLoja.loja_data?.cod_loja} - ${infoLoja.loja_data?.des_loja}`,
      },
      isInvalid: false,
      isRequired: true,
    },

    cod_especie: {
      value: undefined,
      isInvalid: true,
      isRequired: true,
    },

    num_ult_nf_emitida: {
      value: null,
      isInvalid: false,
      isRequired: true,
    },

    flg_inativo: {
      value: false,
      isInvalid: false,
      isRequired: true,
    },
  });
  const [optionsSelectLoja, setOptionsSelectLoja] = useState([]);
  const [optionsSelectEspecie, setOptionsSelectEspecie] = useState([]);
  const [manterDados, setManterDados] = useState(false);

  useEffect(() => {
    setValue('cod_loja', {
      value: infoLoja.loja_data?.cod_loja,
      label: `${infoLoja.loja_data?.cod_loja} - ${infoLoja.loja_data?.des_loja}`,
    });
  }, []);

  const handleChangeLoja = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('cod_loja', val);
      setFormData({
        ...formData,
        cod_loja: { ...formData.cod_loja, value: val, isInvalid },
      });
    },
    [formData, setValue],
  );

  const handleChangeEspecie = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        cod_especie: { ...formData.cod_especie, value: val, isInvalid },
      });
    },
    [formData],
  );

  const handleChangeNumSerie = useCallback(
    (val: number, isInvalidParam: boolean) => {
      if (val.toString().length <= 7 && !val.toString().includes('-')) {
        setValue('num_serie', val);
        setFormData({
          ...formData,
          num_serie: {
            ...formData.num_serie,
            value: val,
            isInvalid: isInvalidParam,
          },
        });
      }
    },
    [formData, setValue],
  );

  const handleChangeultimoNumNf = useCallback(
    (val: number, isInvalidParam: boolean) => {
      if (val.toString().length <= 5 && !val.toString().includes('-')) {
        setFormData({
          ...formData,
          num_ult_nf_emitida: {
            ...formData.num_ult_nf_emitida,
            value: val,
            isInvalid: isInvalidParam,
          },
        });
      }
    },
    [formData],
  );

  /**
   * Evento disparado ao clicar em uma linha da tabela
   * @param param
   */
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);

    const {
      num_serie,
      cod_loja,
      cod_especie,
      des_especie,
      des_doc_fiscal,
      num_ult_nf_emitida,
      des_loja,
      flg_inativo,
    } = row;

    setValue('cod_loja', { label: des_loja, value: cod_loja });
    setValue('num_serie', num_serie);
    setValue('cod_especie', { label: des_especie, value: cod_especie });
    setValue('num_ult_nf_emitida', num_ult_nf_emitida);
    setValue('flg_inativo', flg_inativo);

    setFormData({
      num_serie: {
        value: num_serie,
        isInvalid: false,
        isRequired: true,
      },

      cod_loja: {
        value: { label: des_loja, value: cod_loja },
        isInvalid: false,
        isRequired: true,
      },

      cod_especie: {
        value: {
          label: `${des_especie} - ${des_doc_fiscal}`,
          value: cod_especie,
        },
        isInvalid: false,
        isRequired: true,
      },

      num_ult_nf_emitida: {
        value: num_ult_nf_emitida,
        isInvalid: false,
        isRequired: true,
      },

      flg_inativo: {
        value: flg_inativo,
        isInvalid: false,
        isRequired: true,
      },
    });
    setLoader(true);
    setLoader(false);

    setShowSearch(false);
  };

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = () => {
    setUpdate(false);
    reset();
    setInitInicializado(false);
    setFormData({
      num_serie: {
        value: null,
        isInvalid: false,
        isRequired: true,
      },

      cod_loja: {
        ...formData.cod_loja,
        value: optionsSelectLoja.find((loja: any) => {
          return loja.value === user.user?.loja;
        }),
        isInvalid: false,
      },

      cod_especie: {
        value: { label: 'Informe a espécie', value: undefined },
        isInvalid: true,
        isRequired: true,
      },

      num_ult_nf_emitida: {
        value: null,
        isInvalid: false,
        isRequired: true,
      },

      flg_inativo: {
        value: false,
        isInvalid: false,
        isRequired: true,
      },
    });

    setValue(
      'cod_loja',
      optionsSelectLoja.find((loja: any) => {
        return loja.value === user.user?.loja;
      }),
    );
  };

  /**
   * Listagem da lojas no select
   */

  useEffect(() => {
    const fetchLojas = async () => {
      if (!user.user?.id) return;

      try {
        const response = await api.get('/lojas/usuario');
        const { success, data } = response.data;

        if (success) {
          const lojasOptions = data.map((loja: LojaProps) =>
            formatarOptionLoja(loja),
          );

          const selectedLoja = lojasOptions.find(
            (option: any) => option.value === formData.cod_loja.value?.value,
          );

          setFormData((currentFormData) => ({
            ...currentFormData,
            cod_loja: {
              ...currentFormData.cod_loja,
              value: selectedLoja,
              isInvalid: false,
            },
          }));

          const actives = data.flatMap((loja: LojaProps) => {
            if (loja.flg_inativa !== true) {
              return [formatarOptionLoja(loja)];
            }
            return [];
          });

          setOptionsSelectLoja(actives);
        }
      } catch (error: any) {
        errorHandler(error);
      }
    };

    fetchLojas();
  }, [formData.cod_loja.value?.value, user.user?.id, isUpdate]);

  /**
   * Listagem de especies no select
   */
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/especies');

      if (data.success) {
        const options = data.data
          .filter(
            (especie: Especie) =>
              especie.cod_doc_fiscal === '65' ||
              especie.cod_doc_fiscal === '55',
          )
          .map((especie: Especie) => ({
            label: `${especie.des_especie} - ${especie.des_doc_fiscal}`,
            value: especie.cod_especie,
          }));

        setOptionsSelectEspecie(options);
      }
    })();
  }, []);

  /**
   * Evento reponsavel por cadastrar ou editar um Operador
   * @return
   */

  const onSave = handleSubmit(async (dataForm) => {
    if (Number(dataForm.num_serie) <= 0) {
      toast.warning('É necessário informar a Série.');
      return;
    }
    if (Number(dataForm.num_ult_nf_emitida) <= 0) {
      toast.warning('É necessário informar o número da última NF emitida.');
      return;
    }
    try {
      if (isUpdate) {
        const { data } = await api.put(`/serienf/${dataForm?.num_serie}`, {
          cod_loja: dataForm.cod_loja.value,
          cod_especie: dataForm.cod_especie.value,
          num_ult_nf_emitida: dataForm.num_ult_nf_emitida,
          flg_inativo: dataForm.flg_inativo,
        });

        if (data.success) {
          toast.success(data.message);
          setLoader(false);
          setShowSearch(true);
        }
      } else {
        const { data } = await api.post('/serienf', {
          num_serie: dataForm.num_serie,
          cod_loja: dataForm.cod_loja.value,
          cod_especie: dataForm.cod_especie.value,
          num_ult_nf_emitida: dataForm.num_ult_nf_emitida,
          flg_inativo: dataForm.flg_inativo,
        });

        if (data.success) {
          toast.success(data.message);
          resetFormData();
        }
      }
    } finally {
      setUpdate(false);
      setLoader(false);
    }
  });

  /**
   * Deletar Operador
   */

  const handleInvativar = async () => {
    const { data } = await api.put(
      `/serienf/inactivate/${formData?.num_serie.value}`,
    );

    if (data.success) {
      resetFormData();
      toast.success('Série inativada com sucesso');
      setShowSearch(true);
    }
  };

  const handleSwitch = useCallback(() => {
    setManterDados(!manterDados);
  }, [manterDados]);

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={15}
          newData={() => {
            resetFormData();
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={15}
          title="Cadastro de Série de NF"
          codigoRegistro={[
            { value: formData.num_serie.value || '', des_campo: 'Código' },
          ]}
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleInvativar}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          onSwitch={handleSwitch}
          labelButtonTrash="Inativar"
          deleteMessage="Deseja realmente inativar o registro?"
        >
          <div className="row">
            <div className="col-sm-12 col-md-10">
              <InputSelect
                options={optionsSelectLoja}
                label="Loja"
                isRequired={formData.cod_loja.isRequired}
                setInvalid={formData.cod_loja.isInvalid}
                isDisabled={isUpdate}
                placeholder="Informe a loja"
                value={formData.cod_loja.value}
                iniInicializado={iniInicializado}
                onChange={(newValue: any, isInvalid = true) => {
                  handleChangeLoja(newValue, isInvalid);
                }}
                noOptionsMessage="Nenhum registro encontrado"
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputNumber
                label="Série"
                value={formData.num_serie.value || ''}
                min={0}
                max={9999999}
                isNull
                isUndefined
                placeholder="0"
                isDisabled={isUpdate}
                isRequired
                setInvalid={!!errors.num_serie}
                iniInicializado={!!errors.num_serie}
                onChange={(newValue: number, isInvalidParam = false) => {
                  handleChangeNumSerie(newValue, isInvalidParam);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <InputSelect
                options={optionsSelectEspecie}
                label="Espécie"
                isRequired
                setInvalid={!!errors.cod_especie}
                placeholder="Informe a espécie"
                value={formData.cod_especie.value}
                iniInicializado={!!errors.cod_especie}
                onChange={(newValue: any, isInvalid = true) => {
                  setValue('cod_especie', newValue);
                  handleChangeEspecie(newValue, isInvalid);
                }}
                noOptionsMessage="Nenhum registro encontrado"
              />
            </div>

            <div className="col-sm-12 col-md-3">
              <InputNumber
                label="Última Emissão NF"
                value={formData.num_ult_nf_emitida.value || ''}
                min={0}
                placeholder="0"
                isNull
                isUndefined
                isRequired
                setInvalid={!!errors.num_ult_nf_emitida}
                iniInicializado={!!errors.num_ult_nf_emitida}
                onChange={(newValue: number, isInvalidParam = false) => {
                  setValue('num_ult_nf_emitida', newValue);
                  handleChangeultimoNumNf(newValue, isInvalidParam);
                }}
              />
            </div>

            <div className="col-sm-12 col-md-2 mt-2">
              <ToggleDefault
                labelText="Inativo?"
                setChecked={formData.flg_inativo.value}
                onSwitch={() => {
                  setValue('flg_inativo', !formData.flg_inativo.value);
                  setFormData({
                    ...formData,
                    flg_inativo: {
                      ...formData.flg_inativo,
                      value: !formData.flg_inativo.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default Operador;
